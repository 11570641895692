import type { BlockConfigFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { useBlockTypes } from '@/workflow-edit/composables'
import { computed, type Ref } from 'vue'

export function useFieldGeneration(opts: {
  workflow: Ref<WorkflowDetailsFragment | undefined>
  config: Ref<BlockConfigFragment | undefined>
}) {
  const { workflow, config } = opts
  const { getBlockType } = useBlockTypes()

  const blockType = computed(() => {
    if (!config?.value) return null
    return getBlockType(config?.value?.block)
  })
  const configArgs = computed(() => {
    if (!config?.value) return workflow.value?.result ?? []
    return config?.value?.arguments || []
  })

  const generatedFields = computed(() => {
    // list of base field names for block config settings or workflow inputs
    const fields: string[][] = []
    const blockTypeArgs = blockType.value?.arguments || []
    const argsSeen = new Set<string>()
    for (const blockTypeArg of blockTypeArgs) {
      if (!blockTypeArg.name) continue
      const argument = configArgs.value.find((a) => a.name === blockTypeArg.name) ?? null
      fields.push([blockTypeArg.name])
      if (argument) argsSeen.add(argument.id)
    }
    for (const argument of configArgs.value) {
      // Add custom arguments that are not defined in the block type
      if (argsSeen.has(argument.id)) continue
      fields.push([argument.name])
    }
    return fields
  })

  return { generatedFields }
}
