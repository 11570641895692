<script lang="ts" setup>
import { Column, Spinner } from '@madxnl/dodo-ui'
import { onMounted, watch } from 'vue'
import { RouterView } from 'vue-router'
import { useAuthSession, useCurrentUser, useLoginRedirect } from '../composables'

/**
 * This component is used to require authentication for all child routes.
 * It will redirect to the login page if the user is not authenticated.
 */

const { currentUser } = useCurrentUser()
const { redirectToLogin } = useLoginRedirect()
const { isAuthenticated, loadAuthSession } = useAuthSession()

onMounted(redirectIfNotAuthenticated)

watch(isAuthenticated, redirectIfNotAuthenticated)

async function redirectIfNotAuthenticated() {
  await loadAuthSession()
  if (!isAuthenticated.value) {
    await redirectToLogin()
  }
}
</script>

<template>
  <RouterView v-if="currentUser" />
  <Column v-else padding="l">
    <Spinner />
  </Column>
</template>
