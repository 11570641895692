<script lang="ts" setup>
import { BlockSettingsField } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Column, Row } from '@madxnl/dodo-ui'
import { type useRunWorkflowForm } from './useRunWorkflowForm'

const props = defineProps<{
  runForm: ReturnType<typeof useRunWorkflowForm>
}>()

const { shownArgNames, getArgDetails, getArgWarn, getArgError } = props.runForm
</script>

<template>
  <Column>
    <template v-for="argName of shownArgNames" :key="argName">
      <BlockSettingsField
        :workflow-input-arg="getArgDetails(argName)"
        :field="[argName]"
        :input-data="runForm.data.dataObj"
        :disabled="false"
      />

      <Row v-if="getArgError(argName)" justify="between">
        <p class="form-description dodo-color-danger">
          {{ getArgError(argName) }}
        </p>
      </Row>

      <Row v-if="getArgWarn(argName)" justify="between">
        <p class="form-description dodo-color-danger">
          {{ getArgWarn(argName) }}
        </p>
      </Row>
    </template>
  </Column>
</template>
