import {
  BlockArgumentInputType,
  BlockArgumentType,
  type WorkflowInputArgumentFragment,
  type WorkflowInputArgumentInputSchema,
} from '@/generated/sdk'
import { useWorkflowSettings } from './useWorkflowSettings'

export type FieldType = 'checkbox' | 'text' | 'textarea' | 'number' | 'file' | 'multifile' | 'array'
export type FieldTypeOption = {
  label: string
  value: FieldType
  argumentType: BlockArgumentType
  inputType: BlockArgumentInputType
  neverRequired?: boolean
}

export const inputFieldOptions: FieldTypeOption[] = [
  {
    label: 'Checkbox',
    value: 'checkbox',
    argumentType: BlockArgumentType.Boolean,
    inputType: BlockArgumentInputType.Checkbox,
    neverRequired: true,
  },
  {
    label: 'Text',
    value: 'text',
    argumentType: BlockArgumentType.String,
    inputType: BlockArgumentInputType.Text,
  },
  {
    label: 'Text area',
    value: 'textarea',
    argumentType: BlockArgumentType.String,
    inputType: BlockArgumentInputType.TextArea,
  },
  {
    label: 'Number',
    value: 'number',
    argumentType: BlockArgumentType.Number,
    inputType: BlockArgumentInputType.Number,
  },
  {
    label: 'File',
    value: 'file',
    argumentType: BlockArgumentType.File,
    inputType: BlockArgumentInputType.File,
  },
  {
    label: 'List',
    value: 'array',
    argumentType: BlockArgumentType.Array,
    inputType: BlockArgumentInputType.Unknown,
  },
]

export function useFieldTypeOptions() {
  const { saveWorkflowArg, deleteWorkflowArg } = useWorkflowSettings()

  function getInputFieldType(argument: WorkflowInputArgumentFragment) {
    const option = inputFieldOptions.find(
      (o) => o.inputType === argument.inputType && o.argumentType === argument.argumentType,
    )
    // Display deprecated array type (multifile and array)
    const deprecatedArray = argument.argumentType === 'Array' && argument.inputType !== 'Unknown'
    if (deprecatedArray) {
      return argument.inputType === 'File' ? 'multifile' : 'array'
    }
    return option ? option.value : null
  }

  async function setFieldType(argument: WorkflowInputArgumentFragment, fieldType: FieldType | null) {
    const option = inputFieldOptions.find((o) => o.value === fieldType)
    if (!option) return
    const input: WorkflowInputArgumentInputSchema = {
      id: argument.id,
      argumentType: option.argumentType,
      inputType: option.inputType,
    }
    if (option.argumentType === 'Array') {
      // Set default values for array items
      input.items = {
        id: argument.items?.id,
        name: 'List item',
        description: '',
        argumentType: argument.items?.argumentType || BlockArgumentType.String,
        inputType: argument.items?.inputType || BlockArgumentInputType.Text,
      }
    } else if (argument.items) {
      await deleteWorkflowArg(argument.items)
      input.items = null
    }
    await saveWorkflowArg(input)
    Object.assign(argument, input)
  }

  return {
    getInputFieldType,
    setFieldType,
    inputFieldOptions,
  }
}
