<script lang="ts" setup>
import { MODE_DEV } from '@/environment'
import { NavbarItem } from '@/navigation/components'
import { useCurrency } from '@/ui/composables/useCurrency'
import { useUserOrganization } from '@/user/composables'
import { Spinner } from '@madxnl/dodo-ui'
import { onMounted, ref } from 'vue'
import TopupCredits from './TopupCredits.vue'

const { organization, fetch } = useUserOrganization()
const { formatCurrency } = useCurrency()

const openModal = ref(false)
onMounted(fetch)

function handleOpenTopupModal() {
  // TO DO: Remove if API is implemented
  if (!MODE_DEV) return
  openModal.value = true
}
</script>

<template>
  <NavbarItem @click="handleOpenTopupModal">
    <template v-if="organization">
      {{ formatCurrency(Number(organization?.budget)) }}
    </template>
    <Spinner v-else />
  </NavbarItem>

  <TopupCredits :open="openModal" @close="openModal = false" />
</template>
