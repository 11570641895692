<script setup lang="ts">
import type { BlockConfigDetailsFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useBlockTypes } from '@/workflow-edit/composables'
import { Button, Column, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, toRefs, watch } from 'vue'
import BlockSettingsField from './BlockSettingsField.vue'
import { useSubworkflowSettings } from './composables'
import { useFieldGeneration } from './composables/useFieldGeneration'
import { useManageArguments } from './composables/useManageArguments'

const props = defineProps<{
  workflow?: WorkflowDetailsFragment
  config?: BlockConfigDetailsFragment
  context: 'workflow-output' | 'settings'
  hideFields?: string[]
}>()

const { config, workflow } = toRefs(props)
const { generatedFields } = useFieldGeneration({ config, workflow })
const { addExtraArgument, canAddExtraArgument, isNameTaken } = useManageArguments({ workflow, config })
const { loadSubworkflow } = useSubworkflowSettings()
const { getBlockType } = useBlockTypes()

const blockType = computed(() => getBlockType(config.value?.block))

const subworkflowId = computed(() => props.config?.workflow?.id)
watch(subworkflowId, () => loadSubworkflow(props.config ?? null), { immediate: true })

const newArgName = ref('')

async function clickAddArgument() {
  const argumentType = BlockConfigArgumentType.Constant
  await addExtraArgument(newArgName.value, argumentType)
  newArgName.value = ''
}

const showAddArgument = computed(() => {
  if (!workflow.value?.draft) return false
  if (!canAddExtraArgument.value) return false
  if (subworkflowId.value) return false // Don't show add argument button for subworkflow settings
  return true
})
</script>

<template>
  <p v-if="!generatedFields.length" class="form-description">
    <template v-if="context === 'workflow-output'">
      Add the expected output data generated by the workflow. After running the workflow, this output will be shown in
      the results of the workflow.
    </template>
    <template v-else>No {{ context }} items yet</template>
  </p>

  <Column v-else gap="l">
    <template v-for="(field, i) of generatedFields" :key="i">
      <BlockSettingsField
        v-if="!hideFields?.includes(field.join('.'))"
        :workflow="workflow"
        :config="config"
        :field="field"
        :disabled="workflow && !workflow.draft"
        :block-item="blockType"
      />
    </template>
  </Column>

  <Form v-if="showAddArgument" @submit="clickAddArgument">
    <hr />
    <h5>
      <template v-if="context === 'workflow-output'">Add expected output data</template>
      <template v-else> Add a new {{ context }} item </template>
    </h5>
    <FormItem>
      <Row>
        <TextInput v-model="newArgName" style="flex: 1" type="text" placeholder="Enter a name" />
        <Button :disabled="!newArgName || isNameTaken(newArgName)" type="submit" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>
