<script setup lang="ts">
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { useBlockTypes, useWorkflowBlocks, useWorkflowDetails } from '@/workflow-edit/composables'
import { FormItem, Select, Spinner } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import { useDataSources, useManageArguments } from './composables'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
}>()

const workflowBlock = computed(() => props.workflowBlock)

const { blockTypes } = useBlockTypes()
const { workflow } = useWorkflowDetails()
const { availableDataSources } = useDataSources({ workflow, workflowBlock })
const { saveArgument } = useManageArguments({
  workflow,
  config: computed(() => props.workflowBlock.blockConfig),
})
const { updateBlockConfig } = useWorkflowBlocks({ workflow })

const blockConfig = computed(() => props.workflowBlock.blockConfig)
const isLoopBlock = computed(() => blockConfig.value.block.includes('loop'))
const innerConfig = computed(() => blockConfig.value.loop?.blockConfig)
const listArgument = computed(() => blockConfig.value?.arguments.find((x) => x.name === 'input'))

async function selectListArgument(value: string) {
  if (listArgument.value?.value === value) return
  await saveArgument('input', listArgument.value ?? null, { value })
}

async function selectLoopBlockType(block: string) {
  const config = innerConfig.value
  if (!config || config.block === block) return
  await updateBlockConfig(config, { block })
}
</script>

<template>
  <template v-if="isLoopBlock">
    <h4>Loop block settings</h4>

    <FormItem label="List" description="Select a list to loop over">
      <Select
        v-if="listArgument"
        :model-value="listArgument.value"
        :options="availableDataSources.flatMap((g) => g.references)"
        :disabled="!workflow?.draft"
        @update:model-value="selectListArgument"
      />
      <Spinner v-else />
    </FormItem>

    <FormItem v-if="innerConfig" label="Loop block">
      <Select
        v-if="blockTypes"
        :model-value="innerConfig.block"
        :disabled="!workflow?.draft"
        :options="blockTypes.filter((x) => !x.id.includes('loop')).map((b) => ({ label: b.readableName, value: b.id }))"
        @update:model-value="selectLoopBlockType"
      />
      <Spinner v-else />
    </FormItem>
  </template>
</template>
