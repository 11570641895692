<script lang="ts" setup>
import type { BlockArgumentInputType, BlockConfigFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { PromptField } from '@/workflow-edit/prompt'
import { Button, FormItem, Row, Select, Textarea, TextInput } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useUtils } from '../composables'
import CheckMark from './CheckMark.vue'
import FileInput from './FileInput.vue'
import LargeModalLayout from './LargeModalLayout.vue'
import TwinIcon from './TwinIcon.vue'

const props = defineProps<{
  name: string
  modelValue: unknown
  workflow?: WorkflowDetailsFragment
  config?: BlockConfigFragment
  field: string[]
  disabled: boolean
  placeholder: string
  inputType: BlockArgumentInputType
  choices: null | { label: string; value: string }[]
  disablePromptEditor?: boolean
}>()

type EmittedValue = string[] | string | number | boolean | null

const emit = defineEmits<{
  'update:modelValue': [value: EmittedValue]
}>()
const textareaModalOpen = ref(false)

const { debounce } = useUtils()
const emitValue = (x: EmittedValue) => emit('update:modelValue', x)
const debouncedEmit = debounce(emitValue)

const showPromptEditor = computed(() => {
  if (props.disablePromptEditor) return false
  return props.inputType === 'PromptEditor'
})

const inputBoolean = computed<boolean | undefined>({
  get: () => (props.modelValue == null ? undefined : Boolean(props.modelValue)),
  set: (x) => emitValue(x ?? null),
})

const inputText = computed<string>({
  get: () => (props.modelValue == null ? '' : String(props.modelValue)),
  set: (x) => debouncedEmit.trigger(x),
})

const inputNumber = computed<string>({
  get: () => (props.modelValue == null ? '' : String(props.modelValue)),
  set: (x) => debouncedEmit.trigger(x),
})

const inputSelect = computed<string>({
  get: () => (props.modelValue == null ? '' : String(props.modelValue)),
  set: (x) => emitValue(x),
})

const inputFile = computed<string>({
  get: () => (props.modelValue == null ? '' : String(props.modelValue)),
  set: (x) => emitValue(x),
})
</script>

<template>
  <template v-if="inputType === 'Checkbox'">
    <CheckMark v-model="inputBoolean" :disabled="disabled">
      {{ name }}
    </CheckMark>
  </template>

  <template v-else-if="inputType === 'Number'">
    <TextInput
      v-model="inputNumber"
      type="number"
      :placeholder="placeholder ?? 'Enter a number'"
      :disabled="disabled"
    />
  </template>

  <template v-else-if="inputType === 'Text'">
    <TextInput v-model="inputText" :placeholder="placeholder ?? 'Enter a value'" :disabled="disabled" />
  </template>

  <template v-else-if="inputType === 'Select'">
    <Select
      v-model="inputSelect"
      :options="choices ?? []"
      :placeholder="placeholder ?? 'Select a value'"
      :disabled="disabled"
    />
  </template>

  <template v-else-if="inputType === 'File'">
    <FileInput
      v-model="inputFile"
      :placeholder="placeholder ?? 'Choose a file'"
      :disabled="disabled"
      :multiple="true"
    />
  </template>

  <Button
    v-else-if="inputType === 'TextArea'"
    :aria-label="`${disabled ? 'View' : 'Edit'} ${name}`"
    @click="textareaModalOpen = true"
  >
    <TwinIcon icon="Edit" size="s" />
    {{ disabled ? 'View' : 'Edit' }} {{ name }}
  </Button>

  <PromptField
    v-else-if="showPromptEditor"
    v-model="inputText"
    :workflow="workflow"
    :config="config"
    :disabled="disabled ?? false"
    :name="name"
    :field="field"
  />

  <template v-else>
    <Textarea v-model="inputText" :placeholder="placeholder ?? 'Enter a value'" :disabled="disabled" />
  </template>

  <LargeModalLayout
    :open="textareaModalOpen"
    :title="`${disabled ? 'View' : 'Edit'} ${name}`"
    @close="textareaModalOpen = false"
  >
    <template #content>
      <FormItem :label="name">
        <Textarea v-model="inputText" :disabled="disabled" :placeholder="placeholder" :max-rows="20" />
      </FormItem>
    </template>
    <template #footer="{ close }">
      <Row>
        <Button variant="solid" color="primary" @click="close">Done</Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>
