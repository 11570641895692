<script lang="ts" setup>
import { Button, Icon, Row } from '@madxnl/dodo-ui'

const leftCollapsed = defineModel<boolean>('leftCollapsed')
const rightCollapsed = defineModel<boolean>('rightCollapsed')
</script>

<template>
  <div :class="$style.SidebarLayout">
    <div v-if="$slots['sidebar-left']" :class="[$style.sidebar, $style.left, leftCollapsed && $style.collapsed]">
      <div v-show="!leftCollapsed" :class="$style.sidebarContent">
        <Row gap="xs" justify="between">
          <h2 class="dodo-nowrap"><slot name="sidebar-left-title"></slot></h2>
          <Button size="s" round variant="link" title="Close left sidebar" @click="leftCollapsed = true">
            <Icon size="s" name="left_panel_close" />
          </Button>
        </Row>

        <slot name="sidebar-left"> sidebar left </slot>
      </div>

      <div v-if="leftCollapsed" :class="$style.collapseBtn">
        <Button size="s" round title="Open left sidebar" @click="leftCollapsed = false">
          <Icon size="s" name="left_panel_open" />
        </Button>
      </div>
    </div>

    <div :class="$style.content">
      <slot> content slot </slot>
    </div>

    <div v-if="$slots['sidebar-right']" :class="[$style.sidebar, $style.right, rightCollapsed && $style.collapsed]">
      <div v-show="!rightCollapsed" :class="$style.sidebarContent">
        <Row gap="xs" justify="between" align="start">
          <slot name="sidebar-right-title"></slot>
          <Button size="s" round variant="link" title="Close right sidebar" @click="rightCollapsed = true">
            <Icon size="s" name="right_panel_close" />
          </Button>
        </Row>

        <slot name="sidebar-right"> sidebar right </slot>
      </div>

      <div v-if="rightCollapsed" :class="$style.collapseBtn">
        <Button size="s" round title="Open right sidebar" @click="rightCollapsed = false">
          <Icon size="s" name="right_panel_open" />
        </Button>
      </div>
    </div>
  </div>
</template>

<style module>
.SidebarLayout {
  display: flex;
  flex-direction: row;
  min-height: 0;
  flex-grow: 1;
}
.sidebar {
  border: 0 solid var(--grey-2-lines);
  width: 320px;
  background: var(--dodo-color-background);
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.left {
  border-right-width: 2px;
}
.right {
  border-left-width: 2px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--dodo-spacing-m);
  overflow: auto;
  position: relative;
}
.sidebarContent {
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: var(--dodo-spacing-l);
  padding: var(--dodo-spacing-l);
}
.collapsed {
  width: 0;
  border: 0;
}
.collapsed .collapseBtn {
  position: absolute;
  top: 56px;
  right: 0;
  transform: translate(150%, 0);
}
.right .collapseBtn {
  transform: translate(-16px, 0);
}
.right.collapsed .collapseBtn {
  transform: translate(-16px, 0);
}
</style>
